/* eslint-disable */
import { useState, useEffect, useCallback } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Link from 'next/link'

import axios from 'axios'

import useOutsideClick from '../../utils/helperFunctions'

import { useDispatch, useSelector } from 'react-redux'

import { query } from '../../store/app/searchSlice'

export default function SearchBar(props) {
  const [searchInput, setSearchInput] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const { locale } = useRouter()
  const router = useRouter()

  const dispatch = useDispatch()
  const state = useSelector((state) => state.search.query)

  let searchRef = useOutsideClick(() => {
    props.action()
  })

  //close search bar on Esc key
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      props.action()
    }
  }, [])

  useEffect(() => {
    if (searchInput.length > 0) {
      const loadResult = setTimeout(async () => {
        const response = await axios.get(`/suggestion/autocomplete/words?q=${searchInput}`)
        setSearchResults(response?.data)
      }, 50)
      return () => clearTimeout(loadResult)
    }
    setSearchResults([])

    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [searchInput])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(query(searchInput))
    router.push({
      pathname: '/search',
      query: { query: searchInput },
    })
    props.action()
  }

  const handleClick = async (value) => {
    const data = await dispatch(query(value))
    router.push({
      pathname: '/search',
      query: { query: data.payload },
    })
    props.action()
  }

  return (
    <div
      ref={searchRef}
      className="inline-block transition-all ease-in-out duration-300 absolute right-[200px] left-[200px] z-10 top-[20px]"
      data-aos="fade-left"
      data-aos-delay="50"
      data-aos-duration="300"
    >
      <div className="relative">
        <form onSubmit={handleSubmit}>
          <div className="absolute rtl:left-4 ltr:right-4 bottom-3 cursor-pointer">
            <Image src={'/images/search.svg'} width={18} height={18} alt="Search" unoptimized={true} />
          </div>
          <div
            className={`${searchInput ? 'absolute' : 'hidden'} rtl:left-10 ltr:right-10 bottom-3 cursor-pointer`}
            onClick={() => setSearchInput('')}
          >
            <Image src={'/images/close.svg'} width={18} height={18} alt="close" />
          </div>
          <input
            placeholder={locale === 'ar' ? 'ادﺧﻞ اﻟﻜﻠﻤﺎت اﻟﻤﺮاد اﻟﺒﺤﺚ ﻋﻨﻬﺎ' : 'Enter the words to search for'}
            className="bg-grayscale-10 text-blackscale-500 input-border font-diodrum-arabic h-14 p-4 rtl:pl-10 ltr:pr-10 w-full"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </form>
        <ul className="text-blackscale-500 absolute w-full grid grid-cols-1 divide-y divide-grayscale-100 bg-white mt-5 max-h-60 overflow-y-auto ">
          {searchResults &&
            searchResults.map((result, index) => {
              return (
                <li
                  key={index}
                  className="body-xs diodrum px-6 py-2 hover:bg-grayscale-100 first:pt-4 last:pb-3"
                  onClick={() => handleClick(result.value)}
                >
                  <div className="inline-block w-full cursor-pointer">{result.value}</div>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}
