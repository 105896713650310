import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const submitcontactUsRequest = createAsyncThunk(
  'contactUsRequest/submitcontactUsRequest',
  async (arg, { getState }) => {
    const state = getState()
    const formData = {
      ...state.contactUsRequest.StepOne,
      //...state.contactUsRequest.StepTwo,
    }
    //   const response = await axios({
    //   method: 'GET',
    //   url: `/application/api/tickets/${data.orderNumber}/${data.orderMobile}`,
    //   headers: { 'Content-Type': 'multipart/form-data', 'X-API-KEY': 'WebApp', },
    // })
    // return response
    const response = await fetch(`/contact/api/tickets/${formData.orderNumber}/${formData.orderMobile}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'X-API-KEY': 'WebApp' },
    })
    const data = await response.json()
    return data
  }
)

const contactUsRequestSlice = createSlice({
  name: 'contactUsRequest',
  initialState: {
    InitialStep: 1, //default page stage to show on page load
    StepOne: {},
    StepTwo: {},
    ticketData: [],
    loading: false,
  },
  reducers: {
    initialStep: (state, action) => {
      state.InitialStep = action.payload
    },
    stepOne: (state, action) => {
      state.StepOne = action.payload
    },
    stepTwo: (state, action) => {
      state.StepTwo = action.payload
    },
    clearItems: (state, action) => {
      state.InitialStep = 1
      state.StepOne = action.payload
      state.StepTwo = action.payload
    },
    // setData: (state, action) => {
    //   state.ticketData = action.payload
    // },
  },
  extraReducers(builder) {
    builder.addCase(submitcontactUsRequest.pending, (state, action) => {
      state.status = 'loading'
      state.loading = true
    })
    builder.addCase(submitcontactUsRequest.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.ticketData = action.payload
      state.loading = false
    })
    builder.addCase(submitcontactUsRequest.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
      state.loading = false
    })
  },
})

//actions
export const { initialStep, stepOne, stepTwo, loading, clearItems } = contactUsRequestSlice.actions
export default contactUsRequestSlice.reducer
