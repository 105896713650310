import { useEffect, useState } from 'react'

import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import { useTranslations } from 'next-intl'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Script from 'next/script'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

export default function Topbar() {
  const { locale, asPath } = useRouter()
  const router = useRouter()
  const t = useTranslations('topbar')
  const { theme, setTheme } = useTheme()
  const [fontSize, setFontSize] = useState(100)
  const [disableIncrement, setdisableIncrement] = useState(false)
  const [disableDecrement, setdisableDecrement] = useState(false)
  const [fixedText, setFixedText] = useState()
  const [contactMenu, setContactMenu] = useState()

  const loader = ({ src }) => {
    return src
  }

  const changeTheme = (event) => {
    if (event.target.checked) {
      setTheme('dark')
    } else {
      setTheme('light')
    }
  }

  useEffect(() => {
    setTheme('light')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fontIncrease = () => {
    if (fontSize < 106) {
      setFontSize(fontSize + 2)
    }
  }

  const fontDecrease = () => {
    if (fontSize > 94) {
      setFontSize(fontSize - 2)
    }
  }

  const fetchTopBarText = async () => {
    const response = await axios({
      method: 'GET',
      url: `/fixed-bar-block`,
    })
    setFixedText(response?.data?.length > 0 ? response?.data[0] : null)
  }

  const fetchContactMenu = async () => {
    const response = await axios({
      method: 'GET',
      url: `/menu_items/contact-menu`,
    })
    setContactMenu(response?.data?.length > 0 ? response?.data[0] : null)
  }

  useEffect(() => {
    //enable/disbale increment decrement button
    fontSize > 106 ? setdisableIncrement(true) : setdisableIncrement(false)
    fontSize < 94 ? setdisableDecrement(true) : setdisableDecrement(false)

    //change root font size
    document.documentElement.style.setProperty('font-size', `${fontSize}%`)
  }, [fontSize])

  useEffect(() => {
    fetchTopBarText()
    fetchContactMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  // const removeClass = async () => {
  //   let body = document.getElementsByTagName('body')[0]
  //   if (body.classList.contains('bg-overlay')) {
  //     let classList = body.classList.toString()
  //     const newClassList = classList.replace(' bg-overlay', '')
  //     body.className = newClassList
  //   }
  // }
  // const addClass = async () => {
  //   let body = document.getElementsByTagName('body')[0]
  //   let classList = body.classList
  //   if (!body.classList.contains('bg-overlay')) {
  //     body.className = classList + ' bg-overlay'
  //   }
  // }

  //speech recognition
  const { browserSupportsSpeechRecognition } = useSpeechRecognition()
  const [micArea, setMicArea] = useState()
  const [transcript, setTranscript] = useState('')
  const [finalTranscript, setFinalTranscript] = useState('')

  useEffect(() => {
    if (browserSupportsSpeechRecognition) {
      const recognition = SpeechRecognition.getRecognition()

      if (micArea) {
        recognition.lang = locale === 'en' ? 'en-US' : 'ar-SA'
        recognition.start()
      }
      if (!micArea) {
        recognition.abort()
      }

      recognition.onresult = function (event) {
        setTranscript(event.results[0][0].transcript)
        if (event.results[0].isFinal) {
          setFinalTranscript(event.results[0][0].transcript)
        }
      }
      recognition.onend = () => {
        recognition.stop()
        setMicArea(false)
        setFinalTranscript('')
      }
      recognition.onstart = () => {
        setTranscript(locale === 'en' ? 'Speak now' : 'تكلم الآن')
        setTimeout(() => {
          if (finalTranscript === '') {
            setTranscript(locale === 'en' ? 'Listening...' : 'الاستماع ...')
          }
        }, 1500)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micArea])

  useEffect(() => {
    if (finalTranscript != '' && micArea)
      router.push({
        pathname: '/search',
        query: { query: finalTranscript },
      })
  }, [finalTranscript]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="bg-[#E4E4E4] hidden xl:block z-20 relative">
      <div className=" mx-auto flex items-center justify-between rtl:font-diodrum-arabic text-base text-black h-12">
        <div className="flex items-center">
          <span className="px-4 body-md diodrum text-blackscale-500">199001 </span>
          <FeatherIcon className={'rtl:ml-1 ltr:mr-1'} icon="phone" />
        </div>

        <div className="flex items-center">
          <div className="flex items-center">
            <div className="dropdown-menu border-nav text-black p-3 lg:min-w-full top-[0px] left-0 right-0 before:absolute before:top-0 before:shadow-topbar-dropdown before:left-[-4000px] before:w-[4000px] before:h-full before:bg-grayscale-100 after:absolute after:top-0 after:z-[-1] after:shadow-topbar-dropdown after:right-[-4000px] after:w-[4000px] after:h-full after:bg-grayscale-100">
              <div className="flex justify-between items-center">
                <Script src="https://cdn.userway.org/widget.js" data-account="Jm1Mfwt0Bo"></Script>
                {/* {browserSupportsSpeechRecognition && micArea && (
                  <>
                    <div className="transition-all items-center input-border bg-white flex h-[30px] rounded-sm justify-between p-[10px] w-[150px]">
                      <div className="text-gray-500 leading-6 text-sm truncate">{transcript}</div>
                      <div className="w-5">
                        <FeatherIcon
                          className="text-black hover:text-redscale-400 cursor-pointer w-5"
                          icon="mic"
                          onClick={() => setMicArea(false)}
                        />
                      </div>
                    </div>
                    <span className="rtl:border-l ltr:border-r border-neutral-400 h-5 w-1px align-middle"></span>
                  </>
                )}
                {browserSupportsSpeechRecognition && !micArea && (
                  <div className="px-6 flex items-center cursor-pointer" onClick={() => setMicArea(true)}>
                    <span className="ltr:mr-3 rtl:ml-3 inline-block body-sm diodrum text-blackscale-500">
                      {fixedText?.field_voice_order}
                    </span>
                    <Image src={'/images/mic.svg'} width={20} height={20} alt={fixedText?.field_voice_order} />
                  </div>
                )}

                <span className="rtl:border-l ltr:border-r border-neutral-400 h-5 w-1px align-middle"></span>

                <div className="px-6 flex items-center body-sm diodrum text-blackscale-500">
                  <span className="px-2">{fixedText?.field_change_font}</span>

                  <button className="px-1 mt-2" onClick={fontIncrease} disabled={disableIncrement}>
                    <Image
                      src={'/images/plus-square.svg'}
                      unoptimized={true}
                      loader={loader}
                      width={20}
                      height={20}
                      alt="Plus Square"
                    />
                  </button>
                  <button className="px-1 mt-2" onClick={fontDecrease} disabled={disableDecrement}>
                    <Image
                      src={'/images/minus-square.svg'}
                      unoptimized={true}
                      loader={loader}
                      width={20}
                      height={20}
                      alt="minus square"
                    />
                  </button>
                </div>

                <span className="rtl:border-l ltr:border-r border-neutral-400 h-5 w-1px"></span>

                <div className="flex items-center justify-center px-6">
                  <label htmlFor="topbarToggle" className="flex items-center cursor-pointer">
                    <div className="ltr:mr-4 rtl:ml-4 body-sm diodrum">{fixedText?.field_color_contrast}</div>
                    <div className="relative top-[1px]">
                      <input
                        type="checkbox"
                        id="topbarToggle"
                        checked={theme === 'dark' ? true : false}
                        className="sr-only"
                        onChange={changeTheme}
                      />
                      <div className="block bg-transparent border-blackscale-500 border-[2px] w-6 h-4 rounded-full"></div>
                      <div className="dot absolute right-1 top-[4px] bg-transparent border-blackscale-500 border-[2px] w-[8px] h-[8px] rounded-full transition"></div>
                    </div>
                  </label>
                </div> */}
              </div>
            </div>
          </div>
          <div className="flex items-center px-4">
            <Link href={`/site-map`}>
              <a className="mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-diagram-3-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z"
                  />
                </svg>
              </a>
            </Link>
            <Link href={asPath} locale={locale === 'en' ? 'ar' : 'en'}>
              <a className="body-md font-diodrum-arabic uppercase text-[20px]">{locale === 'en' ? 'ع' : 'En'}</a>
            </Link>
            {/* <Link href={asPath} locale={locale === 'en' ? 'zh-hans' : 'zh-hans'}>
              漢語
            </Link> */}
          </div>
          {/* <div className="dropdown cursor-pointer px-4">
            <FeatherIcon
              icon="settings"
              className="feather-18"
              onMouseEnter={() => addClass()}
              onMouseLeave={() => removeClass()}
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}
