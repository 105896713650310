import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  InitialStep: 1, //default page stage to show on page load
  StepOne: {},
  StepTwo: {},
  StepThree: {},
  StepFour: {},
  StepFive: {},
  loading: false,
}

export const applyVacancy = createAsyncThunk(
  // The name of the action
  'applyFormVacancy/applyVacancy',
  // The payload creator
  async (payload) => {
    const response = await fetch(`/application/api/app/vacancy-apply`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        // 'X-API-KEY': 'WebApp',
        // locale: `${payload.locale === 'en' ? 'english' : 'arabic'}`,
      },
    })
    const res = await response.json()
    return res
    // try {

    // } catch (err) {
    //   return thunkAPI.rejectWithValue({ error: err.message })
    // }
  }
)

const applyFormVacancySlice = createSlice({
  name: 'applyFormVacancy',
  initialState,

  reducers: {
    initialStep: (state, action) => {
      state.InitialStep = action.payload
    },
    stepOne: (state, action) => {
      state.StepOne = action.payload
    },
    stepTwo: (state, action) => {
      state.StepTwo = action.payload
    },
    stepThree: (state, action) => {
      state.StepThree = action.payload
    },
    stepFour: (state, action) => {
      state.StepFour = action.payload
    },
    stepFive: (state, action) => {
      state.StepFive = action.payload
    },
    isCertificateEqValid: (state, action) => {
      state.isCertificateEqValid = action.payload
    },
    isFileOneValid: (state, action) => {
      state.isFileOneValid = action.payload
    },
    isFileTwoValid: (state, action) => {
      state.isFileTwoValid = action.payload
    },
    isFileThreeValid: (state, action) => {
      state.isFileThreeValid = action.payload
    },
    clearItems: (state, action) => {
      state.InitialStep = 1
      state.StepOne = action.payload
      state.StepTwo = action.payload
      state.StepThree = action.payload
      state.StepFour = action.payload
      state.StepFive = action.payload
      state.isCertificateEqValid = false
      state.isFileOneValid = false
      state.isFileTwoValid = false
      state.isFileThreeValid = false
    },
    setData: (state, action) => {
      state.data = action.payload
    },
  },
  extraReducers: {
    [applyVacancy.pending]: (state, action) => {
      // When data is being fetched
      state.status = 'loading'
    },
    [applyVacancy.fulfilled]: (state, action) => {
      // When data is fetched successfully
      state.status = 'successful'

      // Concatenate the new data to the existing data in the array
      state.data = state.data.concat(action.payload)
    },
    [applyVacancy.rejected]: (state, action) => {
      // When data is fetched unsuccessfully
      state.status = 'failed'

      // Update the error message for proper error handling
      state.error = action.error.message
    },
    [applyVacancy.fulfilled]: (state, action) => {
      // Add the new  created on the UI to the existing posts
      state.data.push(action.payload)
    },
  },
})

// Export the reducer logic from the slice
//actions
export const {
  initialStep,
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  stepFive,
  loading,
  clearItems,
  setData,
} = applyFormVacancySlice.actions
export default applyFormVacancySlice.reducer
