import { useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import { useTranslations } from 'next-intl'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

export default function MobileHeader() {
  const t = useTranslations('home')
  const loader = ({ src }) => {
    return src
  }
  const { locale, asPath, pathname } = useRouter()
  const router = useRouter()
  const [fontSize, setFontSize] = useState(100)
  const [disableIncrement, setdisableIncrement] = useState(false)
  const [disableDecrement, setdisableDecrement] = useState(false)
  const [toggleMenu, setToggleMenu] = useState({ isVisible: false })
  const [selectedMenu, setSelectedMenu] = useState({ menuTitle: '' })
  const { theme, setTheme } = useTheme()
  const [mainMenu, setMainMenu] = useState([])
  const [primaryLogo, setPrimaryLogo] = useState()
  const [services, setServices] = useState([])
  const [fixedText, setFixedText] = useState()
  const [contactMenu, setContactMenu] = useState()
  const [selectedService, setSelectedService] = useState({ service: {}, below: [] })
  const [levelOneOpen, setLevelOneOpen] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const fetchHeaderMenu = async () => {
    const menu = await axios({
      method: 'GET',
      url: `/menu_items/main`,
    })
    setMainMenu(menu?.data)
  }

  const fetchSiteLogo = async () => {
    const response = await axios({
      method: 'GET',
      url: `/site-logos/main`,
    })
    setPrimaryLogo(response?.data)
  }

  // const fetchServices = async () => {
  //   const response = await axios({
  //     method: 'GET',
  //     url: `/menu_items/services`,
  //   })
  //   setServices(response?.data)
  // }

  // const handleServices = async (service) => {
  //   setSelectedService(service)
  //   // const response = await axios({
  //   //   method: 'GET',
  //   //   url: `/${service?.uri}`,
  //   // })
  //   // setSelectedService({ service, below: response?.data })
  // }
  const fetchTopBarText = async () => {
    const response = await axios({
      method: 'GET',
      url: `/fixed-bar-block`,
    })
    setFixedText(response?.data?.length > 0 ? response?.data[0] : null)
  }

  const fetchContactMenu = async () => {
    const response = await axios({
      method: 'GET',
      url: `/menu_items/contact-menu`,
    })
    setContactMenu(response?.data?.length > 0 ? response?.data[0] : null)
  }

  const changeTheme = (event) => {
    if (event.target.checked) {
      setTheme('dark')
    } else {
      setTheme('light')
    }
  }

  useEffect(() => {
    setTheme('light')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fontIncrease = () => {
    if (fontSize < 106) {
      setFontSize(fontSize + 2)
    }
  }

  const fontDecrease = () => {
    if (fontSize > 94) {
      setFontSize(fontSize - 2)
    }
  }

  const getClassNames = (ind) => {
    let classes = 'flex items-center w-full justify-between py-2 text-blackscale-500'
    mainMenu[ind]?.below?.map((childItem) => {
      if (
        childItem?.options?.page_id &&
        asPath === '/' + childItem?.options?.slug + '/' + childItem?.options?.page_id + '/'
      ) {
        classes = 'active flex items-center w-full justify-between py-2 text-greenscale-500'
      } else if (
        !childItem?.options?.query &&
        !childItem?.options?.page_id &&
        asPath === '/' + childItem?.options?.slug + '/'
      ) {
        classes = 'active flex items-center w-full justify-between py-2 text-greenscale-500'
      } else if (
        childItem?.options?.query &&
        asPath === '/' + childItem?.options?.slug + '/' + '?id=' + childItem?.options?.query?.id
      ) {
        classes = 'active flex items-center w-full justify-between py-2 text-greenscale-500'
      }
    })
    return classes
  }
  const buildURL = (link) => {
    if (link?.options?.query && link?.options?.query.id) {
      if (link?.options?.page_id === null) {
        return '/' + link?.options?.slug + ('/' + '?id=' + link?.options?.query?.id)
      } else {
        return '/' + link?.options?.slug + (link?.options?.page_id ? '/' + link?.options?.page_id : '')
      }
    } else {
      if (link?.options?.slug && link?.options?.slug === 'external') {
        return link?.uri
      } else {
        if (link?.options?.page_id) {
          let isLastStringDash = link?.options?.slug.substring(
            link?.options?.slug.length,
            link?.options?.slug.length - 1
          )

          if (isLastStringDash.toString() === '/')
            return '/' + link?.options?.slug.slice(0, -1) + '/' + link?.options?.page_id
          else return '/' + link?.options?.slug + '/' + link?.options?.page_id
        } else {
          return '/' + link?.options?.slug
        }
      }
    }
  }
  //last design before multi-level
  // const renderMainMenu = useCallback(() => {
  //   return mainMenu.map((menuItem, ind) => {
  //     return menuItem?.below ? (
  //       <li className="body-md diodrum mb-4" key={menuItem?.title}>
  //         <button
  //           className={getClassNames(ind)}
  //           onClick={() =>
  //             setSelectedMenu((prevSelectedMenu) => ({
  //               menuTitle: prevSelectedMenu.menuTitle !== menuItem?.title ? menuItem?.title : '',
  //             }))
  //           }
  //         >
  //           <Link href={buildURL(menuItem, 1)} key={`menuItem${ind + 1}`}>
  //             <span className="ltr:pr-4 rtl:pl-4 font-semibold ">{menuItem?.title}</span>
  //           </Link>

  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="16"
  //             height="16"
  //             fill="text-purplescale-500 "
  //             className={`bi bi-chevron-down dropdown-arrow ${
  //               selectedMenu.menuTitle === menuItem?.title ? 'rotate-180' : ''
  //             }`}
  //             viewBox="0 0 16 16"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
  //             />
  //           </svg>
  //         </button>
  //         {selectedMenu.menuTitle === menuItem.title ? (
  //           <ul className="px-6">
  //             {menuItem.below.map((childMenu, ind) => {
  //               return (
  //                 <li className="body-md diodrum  mt-4" key={childMenu?.title}>
  //                   {/* <Link
  //                     href={`${locale === 'en' ? '/en' : ''}/${childMenu?.options?.slug || ''}${
  //                       childMenu?.options?.page_id ? '/' + childMenu.options.page_id : ''
  //                     }`}
  //                     key={`childMenu${ind + 1}`}
  //                   > */}
  //                   <Link href={buildURL(childMenu, 1)} key={`childMenu${ind + 1}`}>
  //                     <a
  //                       target={childMenu?.options?.slug === 'external'? "_blank": "_parent"}
  //                       className={`font-semibold
  //                         ${
  //                           childMenu &&
  //                           childMenu.options &&
  //                           childMenu.options.slug &&
  //                           asPath === '/' + childMenu.options.slug + '/' + childMenu.options.page_id + '/'
  //                             ? 'text-greenscale-500'
  //                             : !childMenu.options.query &&
  //                               !childMenu.options.page_id &&
  //                               asPath === '/' + childMenu.options.slug + '/'
  //                             ? 'text-greenscale-500'
  //                             : childMenu.options.query &&
  //                               asPath === '/' + childMenu.options.slug + '/' + '?id=' + childMenu?.options?.query?.id
  //                             ? 'text-greenscale-500'
  //                             : 'text-blackscale-500'
  //                         }
  //                         `}
  //                     >
  //                       {childMenu?.title}
  //                     </a>
  //                   </Link>
  //                 </li>
  //               )
  //             })}
  //           </ul>
  //         ) : null}
  //       </li>
  //     ) : (
  //       <li className="body-md diodrum mb-4" key={ind}>
  //         {/* <Link href={`${locale === 'en' ? '/en' : ''}/${menuItem?.options?.slug || ''}`}> */}
  //         <Link href={`${locale === 'en' ? '/en' : ''}/${buildURL(menuItem, 0)}`}>
  //           <a
  //             className={`font-semibold

  //               ${
  //                 menuItem &&
  //                 menuItem?.options?.query &&
  //                 asPath === '/' + menuItem?.options?.slug + '/' + '?id=' + menuItem?.options?.query?.id
  //                   ? 'text-greenscale-500'
  //                   : !menuItem?.options?.query && asPath.includes(menuItem?.options?.slug)
  //                   ? 'text-greenscale-500'
  //                   : // : menuItem.options.page_id && asPath === '/' + menuItem.options.slug + '/' + menuItem?.page_id +'/'
  //                   // ? 'text-white bg-purplescale-500'
  //                   menuItem.options.slug && asPath === '/' + menuItem.options.slug
  //                   ? 'text-greenscale-500'
  //                   : 'text-blackscale-500'
  //               }
  //               `}
  //           >
  //             {menuItem?.title}
  //           </a>
  //         </Link>
  //       </li>
  //     )
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mainMenu, selectedMenu.menuTitle, locale, pathname])
  const renderMainMenu = useCallback(() => {
    return mainMenu.map((menuItem, ind) => {
      return menuItem?.below ? (
        <div className="body-md diodrum mb-4" key={menuItem?.title}>
          <div
            className="dropdown relative text-purplescale-500 flex-col flex items-center px-2"
            // onMouseEnter={() => addClass()}
            //  onMouseLeave={() => removeClass()}
          >
            <button
              className={getClassNames(ind)}
              onClick={() => {
                setSelectedMenu((prevSelectedMenu) => ({
                  menuTitle: prevSelectedMenu.menuTitle !== menuItem?.title ? menuItem?.title : '',
                }))
                if (levelOneOpen == ind) setLevelOneOpen(null)
                else setLevelOneOpen(ind)
              }}
            >
              <Link href={buildURL(menuItem, 1)} key={`menuItem${ind + 1}`} passHref>
                <span className="ltr:pr-4 rtl:pl-4 font-diodrum-arabic f-16 ">{menuItem?.title}</span>
              </Link>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="text-purplescale-500 "
                className={`bi bi-chevron-down dropdown-arrow ${
                  selectedMenu.menuTitle === menuItem?.title && levelOneOpen === ind ? 'rotate-180' : ''
                }`}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
            {levelOneOpen === ind ? (
              <ul className="w-full flex flex-col px-6">
                {menuItem.below.map((childMenu, ind) => {
                  return childMenu?.below ? (
                    <div
                      className="relative text-purplescale-500 flex items-center"
                      // onMouseEnter={() => addClass()}
                      //  onMouseLeave={() => removeClass()}
                    >
                      <div className="flex flex-col w-full">
                        <div
                          className={`flex items-center w-full rtl:text-right ltr:text-left hover:text-greenscale-500 hover:text-white py-2 font-diodrum-arabic f-16 ${
                            childMenu &&
                            childMenu.options &&
                            childMenu.options.slug &&
                            asPath === '/' + childMenu.options.slug + '/' + childMenu.options.page_id + '/'
                              ? 'text-greenscale-500'
                              : !childMenu.options.query &&
                                !childMenu.options.page_id &&
                                asPath === '/' + childMenu.options.slug + '/'
                              ? 'text-greenscale-500'
                              : childMenu.options.query &&
                                asPath === '/' + childMenu.options.slug + '/' + '?id=' + childMenu?.options?.query?.id
                              ? 'text-greenscale-500'
                              : ''
                          }
                          `}
                          key={`childMenu${ind + 1}`}
                        >
                          <Link href={buildURL(childMenu)}>
                            <a
                              className={`flex items-center w-full border-b-0 hover:border-b-0 text-black font-diodrum-arabic f-16`}
                              key={childMenu?.title}
                              aria-label={childMenu?.title}
                            >
                              <FeatherIcon className={'rtl:ml-2 ltr:mr-2'} icon={childMenu?.options?.icon_name || ''} />
                              <span>{childMenu?.title}</span>
                            </a>
                          </Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="text-purplescale-500"
                            className={`bi bi-chevron-down cursor-pointer ${
                              selectedMenu.menuTitle === childMenu?.title ? 'rotate-180' : ''
                            }`}
                            viewBox="0 0 16 16"
                            onClick={() =>
                              setSelectedMenu((prevSelectedMenu) => ({
                                menuTitle: prevSelectedMenu.menuTitle !== childMenu?.title ? childMenu?.title : '',
                              }))
                            }
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        {selectedMenu.menuTitle === childMenu?.title && (
                          <ul className={`w-full flex flex-col px-6`}>
                            {childMenu?.below.map((childMenu2, ind) => {
                              return (
                                <Link href={buildURL(childMenu2)} key={`childMenu2${ind + 1}`}>
                                  <a
                                    className={`flex items-center w-full rtl:text-right ltr:text-left text-black hover:text-greenscale-500 py-2 f-16 font-diodrum-arabic
                        ${
                          childMenu2 &&
                          childMenu2.options &&
                          childMenu2.options.slug &&
                          asPath === '/' + childMenu2.options.slug + '/' + childMenu2.options.page_id + '/'
                            ? 'text-greenscale-500'
                            : !childMenu2.options.query &&
                              !childMenu2.options.page_id &&
                              asPath === '/' + childMenu2.options.slug + '/'
                            ? 'text-greenscale-500'
                            : childMenu2.options.query &&
                              asPath === '/' + childMenu2.options.slug + '/' + '?id=' + childMenu2?.options?.query?.id
                            ? 'text-greenscale-500'
                            : ''
                        }
                        `}
                                    key={childMenu2?.title}
                                    aria-label={childMenu2?.title}
                                  >
                                    <FeatherIcon
                                      className={'rtl:ml-2 ltr:mr-2'}
                                      icon={childMenu2?.options?.icon_name || ''}
                                    />
                                    <span>{childMenu2?.title}</span>
                                  </a>
                                </Link>
                              )
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Link href={buildURL(childMenu)} key={`childMenu${ind + 1}`}>
                      <a
                        target={childMenu?.options?.slug === 'external' ? '_blank' : '_parent'}
                        className={`flex items-center w-full rtl:text-right ltr:text-left hover:text-greenscale-500 font-diodrum-arabic f-16 text-blackscale-500 hover:border-b-2 hover:border-[#1cd7c5] py-2 ${
                          locale === 'en' ? 'text-[12px]' : ''
                        }
                        ${
                          childMenu &&
                          childMenu.options &&
                          childMenu.options.slug &&
                          asPath === '/' + childMenu.options.slug + '/' + childMenu.options.page_id + '/'
                            ? 'text-greenscale-500'
                            : !childMenu.options.query &&
                              !childMenu.options.page_id &&
                              asPath === '/' + childMenu.options.slug + '/'
                            ? 'text-greenscale-500'
                            : childMenu.options.query &&
                              asPath === '/' + childMenu.options.slug + '/' + '?id=' + childMenu?.options?.query?.id
                            ? 'text-greenscale-500'
                            : ''
                        }
                        `}
                        key={childMenu?.title}
                        aria-label={childMenu?.title}
                      >
                        <FeatherIcon className={'rtl:ml-2 ltr:mr-2'} icon={childMenu?.options?.icon_name || ''} />
                        <span>{childMenu?.title}</span>
                      </a>
                    </Link>
                  )
                })}
              </ul>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="body-md diodrum mb-4 py-2 px-2" key={ind}>
          {/* <Link href={`${locale === 'en' ? '/en' : ''}/${menuItem?.options?.slug || ''}`}> */}
          <Link href={`${locale === 'en' ? '/en' : ''}/${buildURL(menuItem, 0)}`}>
            <a
              className={`font-semibold 
             
                ${
                  menuItem &&
                  menuItem?.options?.query &&
                  asPath === '/' + menuItem?.options?.slug + '/' + '?id=' + menuItem?.options?.query?.id
                    ? 'text-greenscale-500'
                    : !menuItem?.options?.query && asPath.includes(menuItem?.options?.slug)
                    ? 'text-greenscale-500'
                    : // : menuItem.options.page_id && asPath === '/' + menuItem.options.slug + '/' + menuItem?.page_id +'/'
                    // ? 'text-white bg-purplescale-500'
                    menuItem.options.slug && asPath === '/' + menuItem.options.slug
                    ? 'text-greenscale-500'
                    : 'text-blackscale-500'
                }
                `}
            >
              {menuItem?.title}
            </a>
          </Link>
        </div>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMenu, selectedMenu.menuTitle, locale, pathname])
  const getMegaMenuClassName = (ind) => {
    // return 'ltr:pr-4 rtl:pl-4 font-bold text-blackscale-500 capitalize' text-blackscale-500
    let classes = 'flex items-center w-full justify-between py-4 text-blackscale-500'
    services[ind]?.below?.map((childItem) => {
      if (asPath === '/services/' + childItem.options.page_id + '/') {
        classes = 'active flex items-center w-full justify-between py-4 text-greenscale-500 py-4 px-4'
      }
    })
    return classes
  }
  const renderMegaMenu = useCallback(() => {
    return services.map((service, ind) => {
      return service?.below ? (
        <li className="body-md diodrum mb-0" key={service?.title}>
          <button
            className={getMegaMenuClassName(ind)}
            onClick={() =>
              setSelectedMenu((prevSelectedMenu) => ({
                menuTitle: prevSelectedMenu.menuTitle !== service?.title ? service?.title : '',
              }))
            }
          >
            <span className="ltr:pr-4 rtl:pl-4 font-semibold ">{service?.title}</span>
            {/* <svg
              className={`dropdown-arrow ${selectedMenu.menuTitle === service?.title ? 'rotate-180' : ''}`}
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.00307 8C5.77615 7.99886 5.55232 7.94721 5.34784 7.84881C5.14335 7.75041 4.96334 7.60773 4.82085 7.43111L1.07863 2.89778C0.859956 2.62486 0.722349 2.296 0.681488 1.94868C0.640627 1.60136 0.698155 1.24955 0.847517 0.933334C0.968654 0.658512 1.16634 0.424367 1.41696 0.258865C1.66758 0.0933616 1.96054 0.00349605 2.26085 0H9.74529C10.0456 0.00349605 10.3386 0.0933616 10.5892 0.258865C10.8398 0.424367 11.0375 0.658512 11.1586 0.933334C11.308 1.24955 11.3655 1.60136 11.3247 1.94868C11.2838 2.296 11.1462 2.62486 10.9275 2.89778L7.18529 7.43111C7.04281 7.60773 6.86279 7.75041 6.65831 7.84881C6.45382 7.94721 6.23 7.99886 6.00307 8Z"
                fill="#413258"
              />
            </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="text-purplescale-500 "
              className={`bi bi-chevron-down dropdown-arrow ${
                selectedMenu.menuTitle === service?.title ? 'rotate-180' : ''
              }`}
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
          {selectedMenu.menuTitle === service.title ? (
            <ul className="px-6">
              {service.below.map((serviceItem, ind) => {
                return (
                  <li className="body-md diodrum  mt-4" key={serviceItem?.title}>
                    <Link
                      href={`${locale === 'en' ? '/en' : ''}/services/${serviceItem?.options?.page_id || ''}`}
                      key={`serviceItem${ind + 1}`}
                    >
                      <a
                        className={`font-semibold   ${
                          serviceItem &&
                          serviceItem.options &&
                          serviceItem.options.page_id &&
                          asPath === '/services/' + serviceItem.options.page_id + '/'
                            ? 'text-greenscale-500'
                            : 'text-blackscale-500'
                        }`}
                      >
                        {serviceItem?.title}
                      </a>
                    </Link>
                  </li>
                )
              })}
            </ul>
          ) : null}
        </li>
      ) : (
        <li className="body-md diodrum mb-4" key={service?.title} onClick={() => handleServices(service)}>
          {service?.title}
          {/* <Link href="/">
            <a className="font-semibold text-blackscale-500">{service?.title}</a>
          </Link> */}
        </li>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, selectedMenu.menuTitle, asPath])

  const getContactClassNames = () => {
    // return 'ltr:pr-4 rtl:pl-4 font-bold text-blackscale-500 capitalize' text-blackscale-500
    let classes = 'flex items-center w-full justify-between py-4'
    contactMenu?.below?.map((childItem) => {
      if (pathname === '/' + childItem.options.slug) {
        classes = 'active flex items-center w-full justify-between py-4 text-greenscale-500 py-4 px-4'
      }
    })
    return classes
  }

  const renderContactMenu = useCallback(() => {
    return contactMenu?.below.map((menuItem, ind) => {
      return (
        <li className="body-md diodrum mb-4 py-2" key={menuItem?.title}>
          <Link href={`${locale === 'en' ? '/en' : ''}/${menuItem?.options?.slug || ''}`} key={`mainMenu${ind + 1}`}>
            <a
              className={`font-semibold text-blackscale-500 ${
                menuItem && menuItem.options && menuItem.options.slug && pathname === '/' + menuItem.options.slug
                  ? 'text-greenscale-500'
                  : 'text-blackscale-500'
              }`}
            >
              {menuItem?.title}
            </a>
          </Link>
        </li>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactMenu])

  useEffect(() => {
    fetchHeaderMenu()
    fetchSiteLogo()
    // fetchServices()
    fetchTopBarText()
    fetchContactMenu()
  }, [])

  useEffect(() => {
    toggleMObMenu('close')
    fetchHeaderMenu()
    fetchContactMenu()
    //  fetchServices()
    fetchTopBarText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, locale])

  useEffect(() => {
    //enable/disbale increment decrement button
    fontSize > 106 ? setdisableIncrement(true) : setdisableIncrement(false)
    fontSize < 94 ? setdisableDecrement(true) : setdisableDecrement(false)

    //change root font size
    document.documentElement.style.setProperty('font-size', `${fontSize}%`)
  }, [fontSize])

  const toggleMObMenu = async (type) => {
    if (type === 'close') {
      setToggleMenu({ isVisible: false })
      let body = document.getElementsByTagName('body')[0]

      if (body.classList.contains('bg-overlay')) {
        let classList = body.classList.toString()
        const newClassList = classList.replace(' bg-overlay', '')
        body.className = newClassList
      }
    } else {
      setToggleMenu({ isVisible: !toggleMenu.isVisible })
      let body = document.getElementsByTagName('body')[0]
      if (!body.classList.contains('bg-overlay')) {
        let classList = body.classList
        body.className = classList + ' bg-overlay'
      } else {
        let classList = body.classList.toString()
        const newClassList = classList.replace(' bg-overlay', '')
        body.className = newClassList
      }
    }
  }

  useEffect(() => {
    if (searchInput.length > 0) {
      const loadResult = setTimeout(async () => {
        const response = await axios.get(`/suggestion/autocomplete/words?q=${searchInput}`)
        setSearchResults(response?.data)
      }, 50)
      return () => clearTimeout(loadResult)
    }
    setSearchResults([])
  }, [searchInput])

  const handleSubmit = (e) => {
    e.preventDefault()
    router.push({
      pathname: '/search',
      query: { query: searchInput },
    })
  }

  const handleClick = (value) => {
    router.push({
      pathname: '/search',
      query: { query: value },
    })
    setSearchInput('')
  }

  //speech recognition
  const { browserSupportsSpeechRecognition } = useSpeechRecognition()
  const [micArea, setMicArea] = useState()
  const [transcript, setTranscript] = useState('')
  const [finalTranscript, setFinalTranscript] = useState('')

  useEffect(() => {
    if (browserSupportsSpeechRecognition) {
      const recognition = SpeechRecognition.getRecognition()

      if (micArea) {
        recognition.lang = locale === 'en' ? 'en-US' : 'ar-SA'
        recognition.start()
      }
      if (!micArea) {
        recognition.abort()
      }

      recognition.onresult = function (event) {
        setTranscript(event.results[0][0].transcript)
        if (event.results[0].isFinal) {
          setFinalTranscript(event.results[0][0].transcript)
        }
      }
      recognition.onend = () => {
        recognition.stop()
        setMicArea(false)
        setFinalTranscript('')
      }
      recognition.onstart = () => {
        setTranscript(locale === 'en' ? 'Speak now' : 'تكلم الآن')
        setTimeout(() => {
          if (finalTranscript === '') {
            setTranscript(locale === 'en' ? 'Listening...' : 'الاستماع ...')
          }
        }, 1500)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micArea])

  useEffect(() => {
    if (finalTranscript != '' && micArea)
      router.push({
        pathname: '/search',
        query: { query: finalTranscript },
      })
  }, [finalTranscript]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="block xl:hidden">
        <div
          className="h-20 flex items-center p-6 bg-grayscale-100 border-b border-grayscale-200 fixed w-full top-0 left-0 z-20 min-h-[80px]"
          id="header"
        >
          <div className="flex rtl:ml-auto ltr:mr-auto">
            <Link href="/">
              <a>
                {primaryLogo?.length > 0 && (
                  <Image
                    loader={loader}
                    // priority
                    src={primaryLogo[0]?.field_logo}
                    placeholder="/images/logo.svg"
                    width={160}
                    height={60}
                    alt={
                      locale === 'ar' ? 'وزارة الصناعة والثروة المعدنية' : 'Ministry of Industry and Mineral Resources'
                    }
                  />
                )}
              </a>
            </Link>
          </div>

          <div className="flex items-center sm:px-6 px-4">
            <Image
              priority
              src={'/images/vision_2030.svg'}
              width={80}
              height={60}
              placeholder="/images/vision_2030.svg"
              alt={locale === 'ar' ? 'رؤية 2030' : 'Vision 2023'}
              loader={loader}
              unoptimized={true}
            />
          </div>
          <button
            className="flex text-black flex-shrink-0"
            type="button"
            x-description="Mobile menu toggle, controls the 'mobileMenuOpen' state."
            onClick={
              () => toggleMObMenu()
              //         ((toggleMenu) => ({
              //   isVisible: !toggleMenu.isVisible,
              // }))
            }
          >
            <Image
              src={toggleMenu.isVisible ? '/images/menu-close.svg' : '/images/menu.svg'}
              width={24}
              height={24}
              alt="Menu"
              loader={loader}
            />
          </button>
        </div>

        {toggleMenu.isVisible && (
          <div
            className="bg-grayscale-100 p-6 fixed max-w-sm w-full h-screen top-20 shadow-xl flex flex-col overflow-y-auto z-20"
            styles={{ height: 'calc(100vh - 80px)' }}
          >
            <div className="relative">
              <form onSubmit={handleSubmit}>
                <div className="absolute rtl:left-4 ltr:right-4 bottom-2 cursor-pointer" onClick={handleSubmit}>
                  <Image
                    src={'/images/search.svg'}
                    width={18}
                    height={18}
                    alt="Search"
                    loader={loader}
                    unoptimized={true}
                  />
                </div>
                <div
                  className={`${searchInput ? 'absolute' : 'hidden'} rtl:left-10 ltr:right-10 bottom-2 cursor-pointer`}
                  onClick={() => setSearchInput('')}
                >
                  <Image src={'/images/close.svg'} width={18} height={18} alt="close" loader={loader} />
                </div>
                <input
                  placeholder={locale === 'ar' ? ' ادﺧﻞ اﻟﻜﻠﻤﺎت اﻟﻤﺮاد اﻟﺒﺤﺚ ﻋﻨﻬﺎ' : 'Enter the words to search for'}
                  className="bg-white text-blackscale-500 input-border font-diodrum-arabic h-12 p-4 rtl:pl-10 ltr:pr-10 w-full"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </form>
              <ul className="bg-white text-blackscale-500 absolute w-full grid grid-cols-1 divide-y divide-grayscale-100  max-h-60 overflow-y-auto ">
                {searchResults &&
                  searchResults.map((result, index) => {
                    return (
                      <li
                        key={index}
                        className="body-xs diodrum px-6 py-2 hover:bg-grayscale-100 first:pt-4 last:pb-3"
                        onClick={() => handleClick(result.value)}
                      >
                        <div className="inline-block w-full cursor-pointer">{result.value}</div>
                      </li>
                    )
                  })}
              </ul>
            </div>
            {/* render main menu */}
            {mainMenu.length > 0 ? (
              <ul className="border-b border-grayscale-200 py-5 text-blackscale-500">{renderMainMenu()}</ul>
            ) : null}
            <Link href={`/services-guide`}>
              <a className="btn btn-black flex mx-4"> {locale === 'en' ? 'Services Guide' : 'دليل الخدمات'}</a>
            </Link>

            {/* render mega menu */}
            {/* {services.length > 0 ? (
              <ul className="border-b border-grayscale-200 py-5 text-blackscale-500">{renderMegaMenu()}</ul>
            ) : null} */}

            {/* render contact menu */}
            {contactMenu ? (
              <ul className="border-b border-grayscale-200 py-5 text-blackscale-500">
                <li className="body-md diodrum mb-4 py-2">
                  <Link href={'/contact-us'}>
                    <a>
                      <span className="ltr:pr-4 rtl:pl-4 font-semibold text-blackscale-500">{contactMenu?.title}</span>
                    </a>
                  </Link>

                  <ul className="px-6">
                    {contactMenu?.below?.length > 0 && selectedMenu.menuTitle === contactMenu.title
                      ? renderContactMenu()
                      : null}
                  </ul>
                </li>
              </ul>
            ) : null}

            {fixedText && (
              <ul className="pt-6 mb-6">
                <li className="body-md diodrum font-semibold text-blackscale-500 mb-8 flex justify-between">
                  {fixedText?.field_unified_number}
                </li>
                {/* <li className="body-md diodrum font-semibold text-blackscale-500 mb-8 flex justify-between">
                  <span> {fixedText?.field_change_font}</span>

                  <span>
                    <button className="px-1.5" onClick={fontIncrease} disabled={disableIncrement}>
                      <Image src={'/images/plus-square.svg'} width={20} height={20} alt="plus square" loader={loader} />
                    </button>
                    <button className="px-1.5" onClick={fontDecrease} disabled={disableDecrement}>
                      <Image
                        src={'/images/minus-square.svg'}
                        width={20}
                        height={20}
                        alt="minus square"
                        loader={loader}
                      />
                    </button>
                  </span>
                </li>
                {browserSupportsSpeechRecognition && !micArea && (
                  <li
                    className="body-md diodrum font-semibold text-blackscale-500 mb-8 flex justify-between"
                    onClick={() => setMicArea(true)}
                  >
                    <span>{fixedText?.field_voice_order}</span>
                    <span className="px-1.5">
                      <Image src={'/images/mic.svg'} width={20} height={20} alt="mic" loader={loader} />
                    </span>
                  </li>
                )}
                {browserSupportsSpeechRecognition && micArea && (
                  <li className="body-md diodrum font-semibold text-blackscale-500 mb-8">
                    <div className="input-border bg-white h-[40px] rounded-sm p-[10px] flex w-full items-center justify-between">
                      <div className="text-gray-500 leading-6 text-sm truncate">{transcript}</div>
                      <div className="w-5">
                        <FeatherIcon
                          className="text-redscale-600 hover:text-redscale-400 cursor-pointer w-5"
                          icon="mic-off"
                          onClick={() => setMicArea(false)}
                        />
                      </div>
                    </div>
                  </li>
                )}
                <li className="body-md diodrum font-semibold text-blackscale-500 mb-8 ">
                  <label htmlFor="topbarToggleMob" className="flex items-center cursor-pointer justify-between">
                    <div className="ltr:mr-4 rtl:ml-4 body-md diodrum">{fixedText?.field_color_contrast}</div>
                    <div className="relative top-[1px] mx-1.5">
                      <input type="checkbox" id="topbarToggleMob" className="sr-only" onChange={changeTheme} />
                      <div className="block bg-transparent border-blackscale-500 border-[2px] w-6 h-4 rounded-full"></div>
                      <div className="dot absolute right-1 top-[4px] bg-transparent border-blackscale-500 border-[2px] w-[8px] h-[8px] rounded-full transition"></div>
                    </div>
                  </label>
                </li>

                <li className="body-md diodrum font-semibold text-blackscale-500 mb-2">
                  <Link href={asPath} locale={locale === 'en' ? 'ar' : 'en'}>
                    <a className="uppercase text-blackscale-500">{locale === 'en' ? 'Arabic' : 'English'}</a>
                  </Link>
                </li> */}
              </ul>
            )}
          </div>
        )}
      </div>
    </>
  )
}
