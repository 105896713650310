import { createSlice, createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'

export const getParent = createAsyncThunk('opendata/getParent', async ({ locale }) => {
  const response = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_OPEN_DATA_BE_HOST_NAME}category`,
    headers: {
      locale: locale,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=utf-8',
    },
  })

  return response
})

export const getLevels = createAsyncThunk('opendata/getLevels', async ({ locale, parent }) => {
  const response = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_OPEN_DATA_BE_HOST_NAME}category${parent ? '?parent_id[]=' + parent : ''}`,
    headers: {
      locale: locale,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
  return response
})

const openDataSlice = createSlice({
  name: 'openData',
  initialState: {
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    parent: [],
    levelOne: [],
    levelTwo: [],
    filter: [],
    openData: [],
    dateCol: [],
    periods: 'Y',
    exportData: [],
    loading: false,
  },
  reducers: {
    setParent: (state, action) => {
      state.parent = action.payload
    },
    setStepOne: (state, action) => {
      state.stepOne = action.payload
    },
    setStepTwo: (state, action) => {
      state.stepTwo = action.payload
    },
    setStepThree: (state, action) => {
      state.stepThree = action.payload
    },
    setLevelOne: (state, action) => {
      state.levelOne = action.payload
    },
    setLevelTwo: (state, action) => {
      state.levelTwo = action.payload
    },
    setFilters: (state, action) => {
      state.filter = action.payload
    },
    setOpenData: (state, action) => {
      state.openData = action.payload
    },
    setDatesCol: (state, action) => {
      state.dateCol = action.payload
    },
    setPeriods: (state, action) => {
      state.periods = action.payload
    },
    setExportData: (state, action) => {
      state.exportData = action.payload
    },
    resetLevels: (state, action) => {
      state.levelOne = []
      state.levelTwo = []
      state.filter = []
    },
    clearItems: (state, action) => {
      state.stepOne = false
      state.stepTwo = false
      state.stepThree = false
      state.levelOne = []
      state.levelTwo = []
      state.filter = []
      state.periods = 'Y'
    },
    stateStart: (state) => {
      state.loading = true
    },
    stateEnd: (state) => {
      state.loading = false
    },
  },
  // extraReducers: {
  //   [getParent.pending]: (state) => {
  //     state.loading = true
  //   },
  //   [getParent.fulfilled]: (state, { payload }) => {
  //     state.loading = false
  //     state.parent = payload.data.response
  //   },
  //   [getParent.rejected]: (state) => {
  //     state.loading = false
  //   },
  // },
})

export const {
  setParent,
  setStepOne,
  setStepTwo,
  setStepThree,
  setLevelOne,
  setLevelTwo,
  setLevelThree,
  setFilters,
  setOpenData,
  setDatesCol,
  setPeriods,
  setExportData,
  resetLevels,
  clearItems,
  stateStart,
  stateEnd,
  loading,
} = openDataSlice.actions
export default openDataSlice.reducer
