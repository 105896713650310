import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import applyFormReducer from './app/applyFormSlice'
import applyFormVacancyReducer from './app/applyFormVacancySlice'
import contactUsRequestReducer from './app/contactUsRequestSlice'
import contactUsRequestReducerSAP from './app/contactUsRequestSlice-sap'
import contactUsReducer from './app/contactUsSlice'
import openDataRequestReducer from './app/openDataRequestSlice'
import openDataReducer from './app/openDataSlice'
import recruitmentReducer from './app/recruitmentSlice'
import rucFormReducer from './app/rucFormSlice'
import searchReducer from './app/searchSlice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['search', 'stepOne'],
}

const reducers = combineReducers({
  recruitment: recruitmentReducer,
  contactUs: contactUsReducer,
  applyForm: applyFormReducer,
  rucForm: rucFormReducer,
  applyFormVacancy: applyFormVacancyReducer,
  contactUsRequest: contactUsRequestReducer,
  contactUsRequestSAP: contactUsRequestReducerSAP,
  search: searchReducer,
  openDataRequest: openDataRequestReducer,
  openData: openDataReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store
