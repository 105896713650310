import '../styles/globals.css'
import { useEffect } from 'react'

import 'nprogress/nprogress.css'
import 'react-toastify/dist/ReactToastify.css'

import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
import { NextIntlProvider } from 'next-intl'
import { ThemeProvider } from 'next-themes'
import NextApp from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
// import OneSignal from 'react-onesignal'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import 'regenerator-runtime/runtime'
import Layout from '../components/Layout'
import * as gtag from '../lib/gtag'
import store from '../store/store'
import SplashScreen from './SplashScreen'

let persistor = persistStore(store)

const MyApp = ({ Component, messages, pageProps }) => {
  const { locale } = useRouter()
  const router = useRouter()
  const dir = locale === 'ar' ? 'rtl' : 'ltr'

  useEffect(() => {
    AOS.init({
      delay: 180,
      easing: 'ease',
      duration: 1800,
      once: true,
    })
    // OneSignal.init({
    //   appId: '128e0cf5-41b1-4009-a39a-ee68d4d5abe8',
    // })
  }, [])
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // axios.defaults.baseURL = `https://mim-test.halayalla.rocks/${locale}/api/`
  axios.defaults.baseURL = `${process.env.NEXT_PUBLIC_BE_HOST_NAME}index.php` + `/${locale}/api/`
  axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
  axios.defaults.headers.common.Authorization = `Basic b3JkZXJfdXNlcjpPcmRlcl91U2VyMDExMEA=`

  axios.defaults.headers.post['Access-Control-Allow-Headers'] =
    'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, X-Custom-Header, Upgrade-Insecure-Requests'
  useEffect(() => {
    //Nprogressbar
    router.events.on('routeChangeStart', () => NProgress.start())
    router.events.on('routeChangeComplete', () => NProgress.done())
    router.events.on('routeChangeError', () => NProgress.done())

    NProgress.configure({ showSpinner: false })

    //set document drirection on language switch
    document.documentElement.dir = dir
    document.querySelector('body').setAttribute('dir', dir)
  }, [dir, router.events])

  return (
    <NextIntlProvider messages={{ ...messages, ...pageProps.messages }}>
      <ThemeProvider forcedTheme={Component.theme || undefined} attribute="class" defaultTheme={Component.theme}>
        <Provider store={store}>
          <PersistGate
            persistor={persistor}
            // loading={null}
            loading={<SplashScreen />}
            onBeforeLift={() => new Promise((resolve) => setTimeout(resolve, 3000))}
          >
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </NextIntlProvider>
  )
}

MyApp.getInitialProps = async function getInitialProps(context) {
  const { locale } = context.router
  return {
    ...(await NextApp.getInitialProps(context)),
    messages: require(`../locales/common/${locale}.json`),
  }
}

export default MyApp
