import {useEffect, useRef} from 'react'

export default function useOutsideClick(handler) {
    let domNode = useRef()
    useEffect(() => {
        let maybeHandler = (event) => {
            if (!domNode.current.contains(event.target)) {
                handler()
            }
        }
        document.addEventListener('mousedown', maybeHandler)

        return () => {
            document.removeEventListener('mousedown', maybeHandler)
        }
    })

    return domNode
}

export const handleScroll = (ref) => {
    if (typeof window !== 'undefined') {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        })
    }
}

export const handleDivScroll = (ref, divId) => {
    const div = document.getElementById(divId)
    if (typeof div !== 'undefined') {
        div.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        })
    }
}

export const downloadURI = (uri, name) => {
    const link = document.createElement('a')
    link.download = name
    link.href = uri
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const xmlToJson = (xml) => {
    // Create the return object
    let obj = {}
    if (xml.nodeType == 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj['@attributes'] = {}
            for (let j = 0; j < xml.attributes.length; j++) {
                let attribute = xml.attributes.item(j)
                obj['@attributes'][attribute.nodeName] = attribute.nodeValue
            }
        }
    } else if (xml.nodeType == 3) {
        // text
        obj = xml.nodeValue
    }

    // do children
    if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
            let item = xml.childNodes.item(i)
            let nodeName = item.nodeName
            if (typeof obj[nodeName] == 'undefined') {
                obj[nodeName] = xmlToJson(item)
            } else {
                if (typeof obj[nodeName].push == 'undefined') {
                    let old = obj[nodeName]
                    obj[nodeName] = []
                    obj[nodeName].push(old)
                }
                obj[nodeName].push(xmlToJson(item))
            }
        }
    }
    return obj
}

export const generateCSRFSAP = (token) => {
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest()
        xhr.open('GET', '/sap-api/', true)
        // xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('sap_token')}`)
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        xhr.setRequestHeader('X-CSRF-Token', 'Fetch')
        xhr.withCredentials = true
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.getResponseHeader('X-Csrf-Token'))
            } else {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                })
            }
        }
        xhr.send(null)
        xhr.onreadystatechange = function () {
            if (this.readyState == this.HEADERS_RECEIVED) {
                // xhr.getResponseHeader('X-Csrf-Token')
                // console.log(xhr.getAllResponseHeaders())
                return xhr.getResponseHeader('X-Csrf-Token')
            }
        }
    })
}

export function scrollToSectionRef(ref, offset = 0) {
    const element = ref.current;

    if (element) {
        const y =
            element.getBoundingClientRect().top +
            window.pageYOffset +
            offset;

        window.scrollTo({top: y, behavior: "smooth"});
    }
}
