import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchOnSearch = createAsyncThunk('search/fetchOnSearch', async ({ query, page }, { getState }) => {
  const response = await axios({
    method: 'GET',
    url: `/search?key=${query}&page=${page}`,
    headers: {
      Authorization: `No Auth`,
    },
  })
  return response
})

// export const fetchOnPageChange = createAsyncThunk('search/fetchOnPageChange', async ({ query, page }, { getState }) => {
//   const response = await axios({
//     method: 'GET',
//     url: `/search?key=${query}&page=${page}`,
//   })
//   return response
// })

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    Query: '',
    loading: true,
  },
  reducers: {
    query: (state, action) => {
      state.Query = action.payload
    },
  },
  extraReducers: {
    [fetchOnSearch.pending]: (state) => {
      state.loading = true
    },
    [fetchOnSearch.fulfilled]: (state) => {
      state.loading = false
    },
    [fetchOnSearch.rejected]: (state) => {
      state.loading = false
    },
  },
})

//actions
export const { query, loading } = searchSlice.actions
export default searchSlice.reducer
