import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Handle POST request to create a new tickets
export const getRequestData = createAsyncThunk(
  // The name of the action
  'rucForm/getRequestData',
  // The payload creator
  async ({ locale, orderNum, otpNum }, { getState }) => {
    const response = await fetch(`/flow-engine/unfair/${orderNum}/${otpNum}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json', locale: locale },
    })
    const data = await response.json()
    return data
  }
)
export const createUnfair = createAsyncThunk(
  // The name of the action
  'rucForm/createUnfair',
  // The payload creator
  async ({ locale }, { getState }) => {
    const state = getState()
    const prepareFormData = {
      ...state.rucForm?.StepOne,
      //...state.rucForm.StepThree,
    }
    let formData = new FormData()
    state.rucForm?.StepTwo.map((item, index) => {
      console.log(item)
      formData.append(`customs_items[${index}][code]`, item.code)
      formData.append(`customs_items[${index}][description]`, item.description)
      formData.append(`customs_items[${index}][report_description]`, item.report_description)
      formData.append(`customs_items[${index}][product_name]`, item.product_name)
      formData.append(`customs_items[${index}][product_uses]`, item.product_uses)
      formData.append(`customs_items[${index}][suggested_solution]`, item.suggested_solution)
      formData.append(`customs_items[${index}][notes]`, item.notes)
      if (item.attachments.length > 0)
        item.attachments.map((x) => formData.append(`customs_items[${index}][attachments][]`, x))

      // console.log(formData)
    })

    state.rucForm.StepThree.attachment.map((item) => {
      formData.append(`attachments[]`, item)
    })
    // formData.append(`attachments[]`, ...state.rucForm.StepThree.attachment)
    // console.log(prepareFormData)
    for (let key in prepareFormData) {
      if (typeof prepareFormData[key] === 'object') {
        for (let subKey in prepareFormData[key]) {
          if (typeof prepareFormData[key][subKey] === 'object') {
            for (let newKey in prepareFormData[key][subKey]) {
              formData.append(`${key}[${subKey}][${newKey}]`, prepareFormData[key][subKey][newKey])
            }
          }
        }
      } else {
        formData.append(key, prepareFormData[key])
      }
    }
    console.log(state.rucForm.StepThree)
    formData.append('other_factory_branches_number', 0)
    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1])
    }

    // const data = new URLSearchParams()
    // for (const pair of new FormData(prepareFormData)) {
    //   data.append(pair[0], pair[1])
    // }

    const response = await fetch(`/flow-engine/unfair`, {
      method: 'POST',
      body: formData,
      headers: { ContentType: 'multipart/form-data', Accept: 'application/json', locale: locale },
    })
    const res = await response.json()
    return res
  }
)
const rucFormSlice = createSlice({
  name: 'rucForm',
  initialState: {
    InitialStep: 1, //default page stage to show on page load
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    Requestdata: [],
    loading: false,
  },

  reducers: {
    initialStep: (state, action) => {
      state.InitialStep = action.payload
    },
    stepOne: (state, action) => {
      state.StepOne = action.payload
    },
    stepTwo: (state, action) => {
      state.StepTwo = action.payload
    },
    stepThree: (state, action) => {
      state.StepThree = action.payload
    },
    clearItems: (state, action) => {
      state.InitialStep = 1
      state.StepOne = action.payload
      state.StepTwo = action.payload
      state.StepThree = action.payload
    },
    // setData: (state, action) => {
    //   state.data = action.payload
    // },
  },
  extraReducers(builder) {
    builder.addCase(getRequestData.pending, (state, action) => {
      state.status = 'loading'
      state.loading = true
    })
    builder.addCase(getRequestData.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.Requestdata = action.payload
      state.loading = false
    })
    builder.addCase(getRequestData.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
      state.loading = false
    })
    builder.addCase(createUnfair.pending, (state, action) => {
      state.status = 'loading'
      state.loading = true
    })
    builder.addCase(createUnfair.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.Requestdata = action.payload
      state.loading = false
    })
    builder.addCase(createUnfair.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
      state.loading = false
    })
  },
})

// Export the reducer logic from the slice
//actions
export const { initialStep, stepOne, stepTwo, stepThree, loading, clearItems } = rucFormSlice.actions
export default rucFormSlice.reducer
