import React from 'react'

import Image from 'next/image'

const SplashScreen = () => {
  return (
    // <div className="ring">
    //   Loading
    //   <span></span>
    // </div>
    <div className="authenticating-loader">
      <div>
        {/* <img src="/images/logo.svg" alt="logo" height="80px" width="250px" /> */}
        <Image src={'/images/logo.svg'} height={80} width={250} alt="Logo" />
      </div>
    </div>
  )
}

export default SplashScreen
