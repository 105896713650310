/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useCallback } from 'react'

// import GridViewIcon from '@mui/icons-material/GridView'

import MenuIcon from '@material-ui/icons/Menu'
import GridViewIcon from '@mui/icons-material/GridView'
import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import SearchBar from './SearchBar'

export default function Header() {
  // const dispatch = useDispatch()
  const { locale, asPath, pathname } = useRouter()
  const router = useRouter()
  const [scrolled, setScrolled] = useState(false)
  // const mainMenu = useSelector(mainMenuSelector)
  const [mainMenu, setMainMenu] = useState([])
  const [primaryLogo, setPrimaryLogo] = useState()
  const [secondaryLogo, setSecondaryLogo] = useState()
  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState({ service: {}, below: [] })
  const loader = ({ src }) => {
    return src
  }

  //form translation
  const t = useTranslations('header')
  const [searchVisible, setSearchVisible] = useState(false)

  const handleSearchClick = () => {
    setSearchVisible(false)
  }

  const fetchHeaderMenu = async () => {
    const menu = await axios({
      method: 'GET',
      url: `/menu_items/main`,
    })
    setMainMenu(menu?.data)
  }

  const fetchSiteLogo = async () => {
    const response = await axios({
      method: 'GET',
      url: `/site-logos/main`,
    })
    setPrimaryLogo(response?.data)
  }
  const fetchSecondaryLogo = async () => {
    const response = await axios({
      method: 'GET',
      url: `/site-logos/secondary`,
    })
    if (response?.data?.length > 0) {
      // const logos = response?.data[0]?.field_logo.split(',')
      setSecondaryLogo(response?.data || [])
    }
  }

  // const fetchServices = async () => {
  //   const response = await axios({
  //     method: 'GET',
  //     url: `/menu_items/services`,
  //   })
  //   setServices(response?.data)
  //   if (response?.data && response?.data?.length > 0) {
  //     setSelectedService(response?.data[0])
  //   }
  // }

  // const removeClass = async () => {
  //   let body = document.getElementsByTagName('body')[0]
  //   if (body.classList.contains('bg-overlay')) {
  //     let classList = body.classList.toString()
  //     const newClassList = classList.replace(' bg-overlay', '')
  //     body.className = newClassList
  //   }
  // }
  // const addClass = async () => {
  //   let body = document.getElementsByTagName('body')[0]
  //   let classList = body.classList
  //   if (!body.classList.contains('bg-overlay')) {
  //     body.className = classList + ' bg-overlay'
  //   }
  // }
  // const handleServices = async (service) => {
  //   setSelectedService(service)

  //   // const response = await axios({
  //   //   method: 'GET',
  //   //   url: `/${service?.uri}`,
  //   // })
  //   // setSelectedService({ service, below: response?.data })
  // }

  // const handleServicesClick = async (service) => {
  //   let uri = service?.uri?.split('/')
  //   let id = uri?.[uri?.length - 1]
  //   if (id !== undefined) {
  //     removeClass()
  //     router.push({
  //       pathname: `${locale === 'en' ? '/en' : ''}/services/`,
  //       query: { id },
  //     })
  //   }
  // }

  // const handleServicesClick = async (service) => {
  //   let id = service?.options?.query?.id

  //   if (id !== undefined) {
  //     removeClass()
  //     router.push({
  //       pathname: `${locale === 'en' ? '/en' : ''}/services`,
  //       query: { id },
  //     })
  //   }
  // }

  // useEffect(() => {
  //   setServices([])
  //   setSelectedService({})
  // fetchServices()
  // }, [asPath, locale])
  const [selectedMenu, setSelectedMenu] = useState({ menuTitle: '' })

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 10) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    fetchHeaderMenu()
    fetchSiteLogo()
    fetchSecondaryLogo()
    // dispatch(getHeaderMenu())
  }, [locale, asPath])

  const getClassNames = (ind) => {
    let classes = 'flex items-center'

    mainMenu[ind]?.below?.map((childItem) => {
      if (
        childItem?.options?.page_id &&
        asPath === '/' + childItem?.options?.slug + '/' + childItem?.options?.page_id + '/'
      ) {
        classes = 'active flex items-center text-white bg-purplescale-500 py-3 px-3'
      } else if (
        !childItem?.options?.query &&
        !childItem?.options?.page_id &&
        asPath === '/' + childItem?.options?.slug + '/'
      ) {
        classes = 'active flex items-center text-white bg-purplescale-500 py-3 px-3'
      } else if (
        childItem?.options?.query &&
        asPath === '/' + childItem?.options?.slug + '/' + '?id=' + childItem?.options?.query?.id
      ) {
        classes = 'active flex items-center text-white bg-purplescale-500 py-3 px-3'
      }
    })
    return classes
  }
  const buildURL = (link) => {
    if (link?.options?.query && link?.options?.query.id) {
      if (link?.options?.page_id === null) {
        return '/' + link?.options?.slug + ('/' + '?id=' + link?.options?.query?.id)
      } else {
        return '/' + link?.options?.slug + (link?.options?.page_id ? '/' + link?.options?.page_id : '')
      }
    } else {
      if (link?.options?.slug && link?.options?.slug === 'external') {
        return link?.uri
      } else {
        if (link?.options?.page_id) {
          let isLastStringDash = link?.options?.slug.substring(
            link?.options?.slug.length,
            link?.options?.slug.length - 1
          )

          if (isLastStringDash.toString() === '/')
            return '/' + link?.options?.slug.slice(0, -1) + '/' + link?.options?.page_id
          else return '/' + link?.options?.slug + '/' + link?.options?.page_id
        } else {
          return '/' + link?.options?.slug
        }
      }
    }
  }
  const renderMainMenu = useCallback(() => {
    return mainMenu.map((menuItem, ind) => {
      return menuItem?.below ? (
        <div key={menuItem?.title || ind}>
          <div
            className="dropdown relative text-purplescale-500 h-24 flex items-center px-2"
            // onMouseEnter={() => addClass()}
            //  onMouseLeave={() => removeClass()}
          >
            <button className={getClassNames(ind)}>
              <span
                className={`ltr:pr-2 rtl:pl-2 font-diodrum-arabic capitalize ${locale === 'en' ? 'text-[14px]' : ''}`}
              >
                <Link href={buildURL(menuItem)} key={`menuItem${ind + 1}`}>
                  {menuItem?.title}
                </Link>
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down dropdown-arrow"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>

            <div className="dropdown-menu hidden shadow-topbar-dropdown rounded-br rounded-bl absolute bg-white text-black p-8 lg:min-w-[330px] top-24">
              <div className="divide-y divide-solid">
                {menuItem.below.map((childMenu, ind) => {
                  return childMenu?.below ? (
                    <div
                      className="relative text-purplescale-500 flex items-center"
                      // onMouseEnter={() => addClass()}
                      //  onMouseLeave={() => removeClass()}
                      key={ind}
                    >
                      <div className="flex flex-col w-full">
                        <div
                          className={`flex items-center w-full rtl:text-right ltr:text-left hover:bg-purplescale-500 hover:text-white py-4 px-4 ${
                            childMenu &&
                            childMenu.options &&
                            childMenu.options.slug &&
                            asPath === '/' + childMenu.options.slug + '/' + childMenu.options.page_id + '/'
                              ? 'text-white bg-purplescale-500'
                              : !childMenu.options.query &&
                                !childMenu.options.page_id &&
                                asPath === '/' + childMenu.options.slug + '/'
                              ? 'text-white bg-purplescale-500'
                              : childMenu.options.query &&
                                asPath === '/' + childMenu.options.slug + '/' + '?id=' + childMenu?.options?.query?.id
                              ? 'text-white bg-purplescale-500'
                              : ''
                          }
                          `}
                          key={`childMenu${ind + 1}`}
                        >
                          <Link href={buildURL(childMenu)}>
                            <a
                              className={`flex items-center w-full border-b-0 hover:border-b-0 hover:text-white`}
                              key={childMenu?.title}
                              aria-label={childMenu?.title}
                            >
                              <FeatherIcon className={'rtl:ml-2 ltr:mr-2'} icon={childMenu?.options?.icon_name || ''} />
                              <span>{childMenu?.title}</span>
                            </a>
                          </Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className={`bi bi-chevron-down cursor-pointer ${
                              selectedMenu.menuTitle === childMenu?.title ? 'rotate-180' : ''
                            }`}
                            viewBox="0 0 16 16"
                            onClick={() =>
                              setSelectedMenu((prevSelectedMenu) => ({
                                menuTitle: prevSelectedMenu.menuTitle !== childMenu?.title ? childMenu?.title : '',
                              }))
                            }
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        {selectedMenu.menuTitle === childMenu?.title && (
                          <ul className={`w-full flex flex-col`}>
                            {childMenu?.below.map((childMenu2, ind) => {
                              return (
                                <Link href={buildURL(childMenu2)} key={`childMenu2${ind + 1}`}>
                                  <a
                                    className={`flex items-center w-full rtl:text-right ltr:text-left text-black hover:bg-purplescale-500 hover:text-white hover:border-b-2 hover:border-[#1cd7c5] py-4 px-4 f-15 font-diodrum-arabic
                        ${
                          childMenu2 &&
                          childMenu2.options &&
                          childMenu2.options.slug &&
                          asPath === '/' + childMenu2.options.slug + '/' + childMenu2.options.page_id + '/'
                            ? 'text-white bg-purplescale-500'
                            : !childMenu2.options.query &&
                              !childMenu2.options.page_id &&
                              asPath === '/' + childMenu2.options.slug + '/'
                            ? 'text-white bg-purplescale-500'
                            : childMenu2.options.query &&
                              asPath === '/' + childMenu2.options.slug + '/' + '?id=' + childMenu2?.options?.query?.id
                            ? 'text-white bg-purplescale-500'
                            : ''
                        }
                        `}
                                    key={childMenu2?.title}
                                    aria-label={childMenu2?.title}
                                  >
                                    <FeatherIcon
                                      className={'rtl:ml-2 ltr:mr-2'}
                                      icon={childMenu2?.options?.icon_name || ''}
                                    />
                                    <span>{childMenu2?.title}</span>
                                  </a>
                                </Link>
                              )
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Link href={buildURL(childMenu)} key={`childMenu${ind + 1}`}>
                      <a
                        target={childMenu?.options?.slug === 'external' ? '_blank' : '_parent'}
                        className={`flex items-center w-full rtl:text-right ltr:text-left hover:bg-purplescale-500 hover:text-white hover:border-b-2 hover:border-[#1cd7c5] py-4 px-4 ${
                          locale === 'en' ? 'text-[12px]' : ''
                        }
                        ${
                          childMenu &&
                          childMenu.options &&
                          childMenu.options.slug &&
                          asPath === '/' + childMenu.options.slug + '/' + childMenu.options.page_id + '/'
                            ? 'text-white bg-purplescale-500'
                            : !childMenu.options.query &&
                              !childMenu.options.page_id &&
                              asPath === '/' + childMenu.options.slug + '/'
                            ? 'text-white bg-purplescale-500'
                            : childMenu.options.query &&
                              asPath === '/' + childMenu.options.slug + '/' + '?id=' + childMenu?.options?.query?.id
                            ? 'text-white bg-purplescale-500'
                            : ''
                        }
                        `}
                        key={childMenu?.title}
                        aria-label={childMenu?.title}
                      >
                        <FeatherIcon className={'rtl:ml-2 ltr:mr-2'} icon={childMenu?.options?.icon_name || ''} />
                        <span>{childMenu?.title}</span>
                      </a>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" text-purplescale-500 capitalize" key={ind}>
          <Link href={`${locale === 'en' ? '/en' : ''}${buildURL(menuItem)}`}>
            <a
              className={`flex hover:bg-purplescale-500 font-diodrum-arabic hover:text-white items-center ltr:text-left px-2 py-2 rtl:text-right hover:border-b-2 hover:border-[#1cd7c5] w-full ${
                locale === 'en' ? 'text-[12px]' : ''
              }
              ${
                (menuItem &&
                  menuItem?.options?.query &&
                  asPath === '/' + menuItem?.options?.slug + '/' + '?id=' + menuItem?.options?.query?.id) ||
                asPath === '/' + menuItem?.options?.slug + '/'
                  ? 'text-white bg-purplescale-500'
                  : !menuItem?.options?.query && asPath.includes(menuItem?.options?.slug)
                  ? 'text-white bg-purplescale-500'
                  : // : menuItem.options.page_id && asPath === '/' + menuItem.options.slug + '/' + menuItem?.page_id +'/'
                  // ? 'text-white bg-purplescale-500'
                  menuItem.options.slug && asPath === '/' + menuItem.options.slug
                  ? 'text-white bg-purplescale-500'
                  : ''
              }
              `}
              aria-label={menuItem?.title}
            >
              {menuItem?.title}
            </a>
          </Link>
        </div>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMenu, selectedMenu.menuTitle, locale, pathname])

  return (
    <nav className={`${scrolled ? 'drop-shadow-md' : ''} bg-white sticky hidden xl:block top-0 z-10 min-h-[96px]`}>
      <div className="w-full relative mx-auto flex items-center justify-between rtl:font-diodrum-arabic text-[15px] text-primary-color h-24 ">
        <div className=" hidden lg:flex items-center hover:border-b-2 hover:border-[#1cd7c5]">
          <Link href="/">
            <a
              className="px-4"
              aria-label={
                locale === 'ar' ? 'وزارة الصناعة والثروة المعدنية' : 'Ministry of Industry and Mineral Resources'
              }
            >
              {primaryLogo?.length > 0 && (
                <Image
                  loader={loader}
                  // priority
                  src={primaryLogo[0]?.field_logo}
                  //  blurDataURL={"/images/logo.svg"}
                  // placeholder="/images/logo.svg"
                  width={160}
                  height={60}
                  alt={
                    locale === 'ar' ? 'وزارة الصناعة والثروة المعدنية' : 'Ministry of Industry and Mineral Resources'
                  }
                  unoptimized={true}
                />
              )}
            </a>
          </Link>
          {mainMenu && mainMenu.length > 0 ? renderMainMenu() : null}
        </div>
        <div className="flex items-center">
          <Link href={`/services-guide`}>
            <a className="btn btn-black flex mx-4 mx-5"> {locale === 'en' ? 'Services Guide' : 'دليل الخدمات'}</a>
          </Link>
          {searchVisible && <SearchBar action={handleSearchClick} />}
          <div className="flex items-center justify-around">
            <span className="rtl:border-l ltr:border-r border-primary-color h-5 w-1px"></span>

            <div className="rtl:pr-6 ltr:pl-6 cursor-pointer" onClick={() => setSearchVisible(true)}>
              <Image
                src={'/images/search.svg'}
                width={18}
                height={18}
                alt="search"
                loader={loader}
                unoptimized={true}
              />
            </div>

            <div className="px-5">
              <div className="flex items-center">
                <Image
                  src={'/images/vision_2030.svg'}
                  width={80}
                  height={60}
                  alt={locale === 'ar' ? 'رؤية 2030' : 'Vision 2030'}
                  // blurDataURL={"/images/vision_2030.svg"}
                  // priority
                  placeholder="/images/vision_2030.svg"
                  loader={loader}
                  unoptimized={true}
                />

                {/* {secondaryLogo?.length > 0 &&
                secondaryLogo.map((item, ind) => <a key={`secLogo_${ind}`} href={item?.field_logo_url}></a>)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
