import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const submitRecruitment = createAsyncThunk('recruitment/submitRecruitment', async (arg, { getState }) => {
  const state = getState()
  const data = {
    ...state.recruitment.StepTwo,
    ...state.recruitment.StepThree,
    ...state.recruitment.StepFour,
    ...state.recruitment.StepFive,
  }
  let formData = new FormData()

  formData.append('cv_file', state.recruitment.StepOne.cv)
  formData.append('photo', state.recruitment.StepOne.photo)
  formData.append('filename', state.recruitment.StepOne.cv.name)
  formData.append('photoname', state.recruitment.StepOne.photo.name)
  for (let key in data) {
    if (typeof data[key] === 'object') {
      for (let subKey in data[key]) {
        if (typeof data[key][subKey] === 'object') {
          for (let newKey in data[key][subKey]) {
            formData.append(`${key}[${subKey}][${newKey}]`, data[key][subKey][newKey])
          }
        }
      }
    } else {
      formData.append(key, data[key])
    }
  }

  const response = await axios({
    method: 'post',
    // url: 'https://mim-test.halayalla.rocks/en/recuritment/send',
    url: `${process.env.NEXT_PUBLIC_BASE_URL_BE_HOST}/en/recuritment/send`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `No Auth` },
  })
  return response
})

const recruitmentSlice = createSlice({
  name: 'recruitment',
  initialState: {
    InitialStep: 1, //default page stage to show on page load
    StepOne: {},
    StepTwo: {},
    StepThree: {},
    StepFour: {},
    StepFive: {},
    loading: false,
    isCvValid: null,
    isPhotoValid: null,
  },
  reducers: {
    initialStep: (state, action) => {
      state.InitialStep = action.payload
    },
    isCvValid: (state, action) => {
      state.isCvValid = action.payload
    },
    isPhotoValid: (state, action) => {
      state.isPhotoValid = action.payload
    },
    stepOne: (state, action) => {
      state.StepOne = action.payload
    },
    stepTwo: (state, action) => {
      state.StepTwo = action.payload
    },
    stepThree: (state, action) => {
      state.StepThree = action.payload
    },
    stepFour: (state, action) => {
      state.StepFour = action.payload
    },
    stepFive: (state, action) => {
      state.StepFive = action.payload
    },
    clearItems: (state, action) => {
      state.InitialStep = 1
      state.StepOne = action.payload
      state.StepTwo = action.payload
      state.StepThree = action.payload
      state.StepFour = action.payload
      state.StepFive = action.payload
      state.isCvValid = false
      state.isPhotoValid = false
    },
  },
  extraReducers: {
    [submitRecruitment.pending]: (state) => {
      state.loading = true
    },
    [submitRecruitment.fulfilled]: (state) => {
      state.loading = false
    },
    [submitRecruitment.rejected]: (state) => {
      state.loading = false
    },
  },
})

//actions
export const {
  initialStep,
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  stepFive,
  loading,
  isCvValid,
  isPhotoValid,
  clearItems,
} = recruitmentSlice.actions
export default recruitmentSlice.reducer
