import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const submitcontactUsRequest = createAsyncThunk(
  'contactUsRequest/submitcontactUsRequest',
  async (arg, { getState }) => {
    const state = getState()
    const formData = {
      ...state.contactUsRequest.StepOne,
    }

    const response = await fetch(`/sap-api/crm/ticket/${formData.orderNumber}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const data = await response.json()
    return data
  }
)

export const setInvestorDataRequest = createAsyncThunk(
  'contactUsRequest/setInvestorDataRequest',
  async (payload, thunkAPI) => {
    // const response = await fetch(`/sap-api/crm/personal-address?mobile=${payload}`, {
    const response = await fetch(`/sap-api/crm/personal-address?contact_id=${payload}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const data = await response.json()
    thunkAPI.dispatch(setInvestorData(data))
    return data
  }
)

const contactUsRequestSlice = createSlice({
  name: 'contactUsRequest',
  initialState: {
    InitialStep: 1, //default page stage to show on page load
    StepOne: [],
    StepTwo: [],
    ticketData: [],
    loading: false,
    investorData: [],
  },
  reducers: {
    initialStep: (state, action) => {
      state.InitialStep = action.payload
    },
    stepOne: (state, action) => {
      state.StepOne = action.payload
    },
    stepTwo: (state, action) => {
      state.StepTwo = action.payload
    },
    clearItems: (state, action) => {
      state.InitialStep = 1
      state.StepOne = action.payload
      state.StepTwo = action.payload
      state.ticketData = []
      state.investorData = []
    },
    setInvestorData: (state, action) => {
      state.investorData = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(submitcontactUsRequest.pending, (state, action) => {
      state.status = 'loading'
      state.loading = true
    })
    builder.addCase(submitcontactUsRequest.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.ticketData = action.payload
      state.loading = false
    })
    builder.addCase(submitcontactUsRequest.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
      state.loading = false
    })
  },
})

//actions
export const { initialStep, stepOne, stepTwo, loading, clearItems, setInvestorData } = contactUsRequestSlice.actions
export default contactUsRequestSlice.reducer
