import { createSlice } from '@reduxjs/toolkit'

const openDataRequestSlice = createSlice({
  name: 'openDataRequest',
  initialState: {
    isFormSubmitted: false,
  },
  reducers: {
    isFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload
    },
  },
})

//actions
export const { isFormSubmitted } = openDataRequestSlice.actions
export default openDataRequestSlice.reducer
