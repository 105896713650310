import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  InitialStep: 1, //default page stage to show on page load
  StepOne: [],
  StepTwo: [],
  StepThree: [],
  loading: false,
}

// Handle POST request to create a new tickets
export const getTickets = createAsyncThunk(
  // The name of the action
  'contactUs/getTickets',
  // The payload creator
  async (payload) => {
    const response = await fetch(`/contact/api/tickets/store/`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'WebApp',
        locale: `${payload.locale === 'en' ? 'english' : 'arabic'}`,
      },
    })
    const res = await response.json()
    return res
    // try {

    // } catch (err) {
    //   return thunkAPI.rejectWithValue({ error: err.message })
    // }
  }
)

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,

  reducers: {
    initialStep: (state, action) => {
      state.InitialStep = action.payload
    },
    stepOne: (state, action) => {
      state.StepOne = action.payload
    },
    stepTwo: (state, action) => {
      state.StepTwo = action.payload
    },
    stepThree: (state, action) => {
      state.StepThree = action.payload
    },
    clearItems: (state, action) => {
      state.InitialStep = 1
      state.StepOne = action.payload
      state.StepTwo = action.payload
      state.StepThree = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
  },

  extraReducers: {
    [getTickets.pending]: (state, action) => {
      // When data is being fetched
      state.status = 'loading'
    },
    [getTickets.fulfilled]: (state, action) => {
      // When data is fetched successfully
      state.status = 'successful'

      // Concatenate the new data to the existing data in the array
      state.data = state.data.concat(action.payload)
    },
    [getTickets.rejected]: (state, action) => {
      // When data is fetched unsuccessfully
      state.status = 'failed'

      // Update the error message for proper error handling
      state.error = action.error.message
    },
    [getTickets.fulfilled]: (state, action) => {
      // Add the new  created on the UI to the existing posts
      state.data.push(action.payload)
    },
  },
})

// Export the reducer logic from the slice
//actions
export const { initialStep, stepOne, stepTwo, stepThree, loading, clearItems, setData } = contactUsSlice.actions
export default contactUsSlice.reducer
