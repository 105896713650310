import React, { useState, useEffect } from 'react'

import dynamic from 'next/dynamic'

const Footer = dynamic(() => import('../Footer'))
import Header from '../Header'
import MobileHeader from '../MobileHeader'
import Topbar from '../Topbar'

export default function Layout({ children }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <>
      <main className="xl:pt-0 pt-20 block bg-white">
        <header>
          {screenWidth >= 1280 && <Topbar />}
          <Header />
          {screenWidth < 1280 && <MobileHeader />}
        </header>
        {children}
        <Footer />
      </main>
    </>
  )
}
